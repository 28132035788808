<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">{{ tt('release_data') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="height:500px">
                    <div class="row">
                        <div class="col-6">
                            <label class="form-control-label">{{ tt('import_to') }} <span class="text-danger">*</span></label>
                            <file-input @change="changeImport" :ref="'file'+key"></file-input>
                            
                            <div class="mt-2">
                                <base-button class="col-12" size="large" type="primary" @click="importExcel" :disabled="inputImportReport.excel == '' || loadingImport">
                                    <span v-if="loadingImport"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
                                    <span v-else>{{ tt('import') }}</span>
                                </base-button>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <img src="/assets/smartcat/export.png" alt="Data Is Empty" width="350px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import importReport from '@/services/report/import.service';
    
    export default {        
        data() {
            return {     
                table: {
                    data: []
                }, 
                inputImportReport: {
                    excel: '',
                },
                btnDownload: {
                    disabled: true
                },
                btnImport: {
                    disabled: true,
                },
                token: localStorage.token,
                loadingImport:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, importReport.index()).onSuccess(function(response) {
                    context.dataImportReport = response.data.data
                }).call();
            },
            changeImport(files) {
                this.inputImportReport.excel = files[0]
            },
            changeTableDownload() {
                if (this.inputImportReport.table_download == '') {
                    this.btnDownload.disabled = true
                } else {
                    this.btnDownload.disabled = false
                }
            },
            importExcel() {
                let context = this;
                let formData = new FormData();
                this.loadingImport = true

                formData.append('excel', context.inputImportReport.excel);
                Api(context, importReport.import_release(formData)).onSuccess(function(response) {
                    console.log(response)
                    context.$notify({
                        message: response.data.message,
                        type: "success",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }).onError(function(error) {
                    context.$notify({
                        message: 'Error',                  
                        type: 'red'
                    });
                }).onFinish(function() {
                    context.loadingImport = false
                }).call();
            }
        }   
    };
</script>
<style></style>
